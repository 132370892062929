import React from 'react'
import { Form } from './form'
import Layout from '../Layout'
import styled from 'styled-components'
import { Message } from './message'
import { Landing } from '../landing'
import { TriRow } from '../trioRow'
import { graphql, useStaticQuery } from 'gatsby'

const Contact = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "Home_Renovations_(Unsplash).jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <>
      <Landing
        data={data?.placeholderImage?.childImageSharp?.fluid}
        copy={
          <h1>
            Get a Quote <br /> Today! <TriRow />
          </h1>
        }
      />
      <TriRow hidden />
    </>
  )
}

export default Contact
