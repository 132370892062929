import React from 'react'
import styled from 'styled-components'

export const TriRow = ({ hidden }) => {
  return (
    <StyledTriRow hidden={hidden}>
      <a
        className="colour-a"
        href="https://www.google.ca/maps/place/Vancouver,+BC/@49.2095422,-122.8441003,10.75z/data=!4m5!3m4!1s0x548673f143a94fb3:0xbb9196ea9b81f38b!8m2!3d49.2827291!4d-123.1207375"
        target="_blank"
        rel="noopener noreferrer"
      >
        <h2>Location</h2>
        <p>Greater Vancouver</p>
      </a>
      <a className="colour-b" href="mailto:reliableconstructionltd0@gmail.com">
        <h2>Email</h2>
        <p>reliableconstructionltd0@gmail.com</p>
      </a>
      <a className="colour-c" href="tel:604-376-1224">
        <h2>Phone</h2>
        <p>+1(604)376-1224</p>
      </a>
    </StyledTriRow>
  )
}

const StyledTriRow = styled.section`
  padding: 1rem;
  display: ${({ hidden }) => (hidden ? 'none' : 'flex')};
  flex-direction: row;
  grid-column: 1 / 4;
  padding-top: 4rem;
  width: 100%;
  flex-wrap: wrap;

  @media (max-width: 1082px) {
    display: ${({ hidden }) => (hidden ? 'flex' : 'none')};
  }

  a {
    flex: 1;
    width: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 2rem;
    margin: 1rem;
    border: none;
    cursor: pointer;
    border-radius: 5px;

    @media (max-width: 500px) {
      padding: 1rem;
      margin: 0.5rem;
      width: 100%;
      flex-wrap: wrap;
    }
  }

  .colour-a {
    background-color: var(--brand);
    color: var(--primary);

    p {
      letter-spacing: 1px;
      text-align: center;
      line-height: 25px;
    }
  }

  .colour-b {
    background-color: var(--text);

    h2,
    p {
      color: var(--primary);
    }

    p {
      letter-spacing: 1px;
      font-size: 1rem;
      word-break: break-all;
    }
  }

  .colour-c {
    background-color: var(--secondary);

    h2,
    p {
      color: var(--text);
    }

    p {
      letter-spacing: 1px;
    }
  }
`
